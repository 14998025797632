<template>
  <div class="container">
    <div v-html="ysxy" />
  </div>
</template>

<script>
import * as API_Common from "@/api/common.js";

export default {
  name: "Protocol",
  data() {
    return {
      ysxy: "",
      pageType: this.$route.query.pageType,
      channelCode: this.$route.query.channelCode,
      client: this.MixinClientBrowser(),
    };
  },
  created() {
    this.getContent();
  },
  methods: {
    getContent() {
      let data = {
        pageType: this.$route.query.pageType,
      };
      if (this.channelCode) {
        data.channelCode = this.channelCode;
      }
      API_Common.getContent(data).then((res) => {
        this.ysxy = res.data.content;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 0.5rem 0.36rem;
  height: 100vh;
  overflow: auto;
  font-size: 0.25rem;
  padding: 0.2rem;
  line-height: 0.5rem;
  text-align: justify;
}
</style>
